import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import './404.scss';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404 | Page Not Found" />
    <section className="four-oh-four-page">
      <div className="wrapper">
        <h1>
          Page not found
        </h1>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
